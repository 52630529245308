import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper } from '@material-ui/core';
import { Radio } from 'antd';
import { CameraAlt, Block } from '@material-ui/icons';
import { QrReader } from 'react-qr-reader';
import { API } from 'aws-amplify';
import { setAuth, setLoading } from '../../redux';
import '../../style/conference.css';

const clearConferenceInfo = {
    conferenceId: '', title: '', date: '', starttime: '', endtime: '', room: '', webconference_info: '',
    webconference_remark: '', username: '', name: '', email: '', department: '', position: '', status: '',
    status2: '', status3: '', status4: '', status5: '', status6: '', status7: '',status8: '', status9: '',
    status10: '',tablet: '', result_status: '', result_status1: '', result_status2: '', result_status3: '',
    result_status4: '', result_status5: '', result_status6: '', result_status7: '', result_status8: '',
    result_status9: '', result_status10: '', section_enable: '0', section: '', section2_enable: '0', section2: '',
    section3_enable: '0', section3: '', section4_enable: '0', section4: '', section5_enable: '0', section5: '',
    section6_enable: '0', section6: '', section7_enable: '0', section7: '', section8_enable: '0', section8: '',
    section9_enable: '0', section9: '', section10_enable: '0', section10: '',
}

const clearSections_no = {
    section_no: 'A', section2_no: 'A', section3_no: 'A', section4_no: 'A', section5_no: 'A', section6_no: 'A',
    section7_no: 'A', section8_no: 'A', section9_no: 'A', section10_no: 'A'
}

class ConferenceAttendeeMaintenance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qrReaderVisible: false,
            qrProcessing: false,
            conferenceInfoDialogOpen: false,
            messageDialogOpen: false,
            ...clearConferenceInfo,
            ...clearSections_no,
        }
    }

    handleResult = (result, error) => {
        if (!!result && !this.state.qrProcessing && !this.state.conferenceInfoDialogOpen) {
            let dataStr = result?.text;
            this.setState({ qrProcessing: true }, () => {
                this.getAttendeeInfo(dataStr);
            })
        }
    }

    async getAttendeeInfo(dataStr) {
        this.props.setLoading(true);
        let data = JSON.parse(dataStr);
        data['operator'] = this.props.auth.user.username;
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'getAttendeeInfo',
                "operator": this.props.auth.user.username,
                ...data
            }
        }).then((response) => {
            let data = response[0];
            let newSessions_no = {}
            let section_counter = 0
            for (let i = 1; i <= 10; i++) {
                let tmp_section_enable_key = 'section_enable'
                let tmp_section_no_key = 'section_no'
                if (i > 1) {
                    tmp_section_enable_key = 'section' + i + '_enable'
                    tmp_section_no_key = 'section' + i + '_no'
                }
                if(data.hasOwnProperty(tmp_section_enable_key) && data[tmp_section_enable_key] === '1') {
                    newSessions_no[tmp_section_no_key] = String.fromCharCode('A'.charCodeAt(0) + section_counter);
                    section_counter++
                }
            }
            this.setState({
                qrProcessing: false,
                conferenceInfoDialogOpen: true,
                ...data,
                ...newSessions_no,
                result_status: data.result_status ? data.result_status : data.status,
                result_status1: data.result_status1 ? data.result_status1 : data.status1,
                result_status2: data.result_status2 ? data.result_status2 : data.status2,
                result_status3: data.result_status3 ? data.result_status3 : data.status3,
                result_status4: data.result_status4 ? data.result_status4 : data.status4,
                result_status5: data.result_status5 ? data.result_status5 : data.status5,
                result_status6: data.result_status6 ? data.result_status6 : data.status6,
                result_status7: data.result_status7 ? data.result_status7 : data.status7,
                result_status8: data.result_status8 ? data.result_status8 : data.status8,
                result_status9: data.result_status9 ? data.result_status9 : data.status9,
                result_status10: data.result_status10 ? data.result_status10 : data.status10,
            });
            this.props.setLoading(false);
        }).catch((error) => {
            console.log(error);
            this.setState({ qrProcessing: false });
            this.props.setLoading(false);
        })
    }

    async updatePresenceResultStatus() {
        this.props.setLoading(true);
        await API.post('uamrsapi', '/conference', {
            headers: {
                'Content-Type': 'application/json'
            },
            body: {
                'operation': 'updatePresenceResultStatus',
                'operator': this.props.auth.user.username,
                'conferenceId': this.state.conferenceId,
                'username': this.state.username,
                'result_status': this.state.result_status,
                'result_status2': this.state.result_status2,
                'result_status3': this.state.result_status3,
                'result_status4': this.state.result_status4,
                'result_status5': this.state.result_status5,
                'result_status6': this.state.result_status6,
                'result_status7': this.state.result_status7,
                'result_status8': this.state.result_status8,
                'result_status9': this.state.result_status9,
                'result_status10': this.state.result_status10,
            }
        }).then((response) => {
            this.setState({ messageDialogOpen: true });
            this.props.setLoading(false);
        }).catch((error) => {
            console.log(error);
            this.props.setLoading(false);
        })
    }

    render() {
        let conferenceDate = this.state.date.substring(0, 4) + '年' + this.state.date.substring(4, 6) + '月' + this.state.date.substring(6) + '日';
        return (
            <>
                <Paper elevation={0} className='body-init' style={{ width: '1000px' }}>
                    <Container style={{ height: '500px' }}>
                        <Grid style={{ margin: '20px auto' }} item lg={12} xs={12}>
                            <h4 style={{ textAlign: 'left' }}>会議出欠実績入力</h4>
                            <Divider style={{ marginBottom: '20px' }} />
                            <Grid className='grid-top' item lg={12} xs={12} style={{ textAlign: 'right' }}>
                                <Button variant='outlined' style={{ outline: 'none', color: '#1976D2', fontWeight: 'bold' }} size='small'
                                    onClick={() => { this.props.history.push('/calendarSchedule') }}>戻る</Button>
                            </Grid>
                            <Grid className='grid-top' item lg={12} xs={12} style={{ textAlign: 'center' }}>
                                <Button variant='contained' style={{ outline: 'none', backgroundColor: this.state.qrReaderVisible ? '#828282' : '#1976D2', color: '#ffffff', display: 'initial', margin: '5px', width: '150px' }} size='large'
                                    onClick={() => { this.setState({ qrReaderVisible: true }) }} disabled={this.state.qrReaderVisible}><CameraAlt fontSize='large' /><span style={{ display: 'block' }}>QRコード読込</span></Button>
                                <Button variant='contained' style={{ outline: 'none', backgroundColor: this.state.qrReaderVisible ? '#1976D2' : '#828282', color: '#ffffff', display: 'initial', margin: '5px', width: '150px' }} size='large'
                                    onClick={() => { this.setState({ qrReaderVisible: false }) }} disabled={!this.state.qrReaderVisible}><Block fontSize='large' /><span style={{ display: 'block' }}>停止</span></Button>
                            </Grid>
                            <Grid item lg={12} xs={12} style={{ textAlign: 'center' }}>
                                <div className='qrReaderDiv'>
                                    <div className='qrReaderShadow' />
                                    {this.state.qrReaderVisible ? <QrReader onResult={this.handleResult} className='qrReader' /> : <></>}
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
                <Dialog
                    open={this.state.conferenceInfoDialogOpen}
                    onClose={() => { this.setState({ conferenceInfoDialogOpen: false, ...clearConferenceInfo }) }}
                    fullWidth
                    maxWidth='sm'
                    PaperProps={{
                        style: {
                            height: '600px'
                        }
                    }}
                >
                    <DialogContent>
                        <h6><b>会議情報</b></h6>
                        <Divider />
                        {this.state.conferenceId ?
                            <>
                                <Grid container style={{ marginBottom: '2%' }}>
                                    <Grid item xs={4}>会議名称</Grid>
                                    <Grid item xs={8}>{this.state.title}</Grid>
                                    <Grid item xs={4}>時刻</Grid>
                                    <Grid item xs={8}>{conferenceDate} {this.state.starttime} ～ {this.state.endtime}</Grid>
                                    <Grid item xs={4}>会議場所</Grid>
                                    <Grid item xs={8}>{this.state.room}</Grid>
                                </Grid>
                                <h5><b>出席者情報</b></h5>
                                <Divider />
                                {this.state.username ?
                                    <>
                                        <Grid container style={{ marginBottom: '2%', fontSize: '120%' }}>
                                            <Grid item xs={4}>氏名</Grid>
                                            <Grid item xs={8}>{this.state.name}</Grid>
                                            <Grid item xs={4}>組合名</Grid>
                                            <Grid item xs={8}>{this.state.department}</Grid>
                                            <Grid item xs={4}>役職名</Grid>
                                            <Grid item xs={8}>{this.state.position}</Grid>
                                            <Grid item xs={4}>出欠予定</Grid>
                                            <Grid item xs={8}>
                                                {this.state.status === '1' ? this.state.section_no + ': 出席 ' : this.state.status === '2' ? this.state.section_no + ': 欠席 ' : this.state.status === '3' ? this.state.section_no + ': Web ' : ''}
                                                {this.state.status2 === '1' ? this.state.section2_no + ': 出席 ' : this.state.status2 === '2' ? this.state.section2_no + ': 欠席 ' : this.state.status2 === '3' ? this.state.section2_no + ': Web ' : ''}
                                                {this.state.status3 === '1' ? this.state.section3_no + ': 出席 ' : this.state.status3 === '2' ? this.state.section3_no + ': 欠席 ' : this.state.status3 === '3' ? this.state.section3_no + ': Web ' : ''}
                                                {this.state.status4 === '1' ? this.state.section4_no + ': 出席 ' : this.state.status4 === '2' ? this.state.section4_no + ': 欠席 ' : this.state.status4 === '3' ? this.state.section4_no + ': Web ' : ''}
                                                {this.state.status5 === '1' ? this.state.section5_no + ': 出席 ' : this.state.status5 === '2' ? this.state.section5_no + ': 欠席 ' : this.state.status5 === '3' ? this.state.section5_no + ': Web ' : ''}
                                                {this.state.status6 === '1' ? this.state.section6_no + ': 出席 ' : this.state.status6 === '2' ? this.state.section6_no + ': 欠席 ' : this.state.status6 === '3' ? this.state.section6_no + ': Web ' : ''}
                                                {this.state.status7 === '1' ? this.state.section7_no + ': 出席 ' : this.state.status7 === '2' ? this.state.section7_no + ': 欠席 ' : this.state.status7 === '3' ? this.state.section7_no + ': Web ' : ''}
                                                {this.state.status8 === '1' ? this.state.section8_no + ': 出席 ' : this.state.status8 === '2' ? this.state.section8_no + ': 欠席 ' : this.state.status8 === '3' ? this.state.section8_no + ': Web ' : ''}
                                                {this.state.status9 === '1' ? this.state.section9_no + ': 出席 ' : this.state.status9 === '2' ? this.state.section9_no + ': 欠席 ' : this.state.status9 === '3' ? this.state.section9_no + ': Web ' : ''}
                                                {this.state.status10 === '1' ? this.state.section10_no + ': 出席' : this.state.status10 === '2' ? this.state.section10_no + ': 欠席' : this.state.status10 === '3' ? this.state.section10_no + ': Web' : ''}
                                            </Grid>
                                        </Grid>
                                        <h5><b>出欠実績記入</b></h5>
                                        <Divider />
                                        <Grid container style={{fontSize: '120%'}}>
                                            {this.state.section_enable !=='1' && this.state.section2_enable !=='1' && this.state.section3_enable !=='1' && 
                                                this.state.section4_enable !=='1' && this.state.section5_enable !=='1' && this.state.section6_enable !=='1' && 
                                                this.state.section7_enable !=='1' && this.state.section8_enable !=='1' && this.state.section9_enable !=='1' && 
                                                this.state.section10_enable !=='1' && 
                                                <Grid item xs={12}><label>対象セッションが存在しません。</label></Grid>
                                            }
                                            {this.state.section_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section' in this.state && this.state.section ? (this.state.section + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status: event.target.value }) }} value={this.state.result_status}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section2_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section2' in this.state && this.state.section2 ? (this.state.section2 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status2: event.target.value }) }} value={this.state.result_status2}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section3_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section3' in this.state && this.state.section3 ? (this.state.section3 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status3: event.target.value }) }} value={this.state.result_status3}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section4_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section4' in this.state && this.state.section4 ? (this.state.section4 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status4: event.target.value }) }} value={this.state.result_status4}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section5_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section5' in this.state && this.state.section5 ? (this.state.section5 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status5: event.target.value }) }} value={this.state.result_status5}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section6_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section6' in this.state && this.state.section6 ? (this.state.section6 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status6: event.target.value }) }} value={this.state.result_status6}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section7_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section7' in this.state && this.state.section7 ? (this.state.section7 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status7: event.target.value }) }} value={this.state.result_status7}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section8_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section8' in this.state && this.state.section8 ? (this.state.section8 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status8: event.target.value }) }} value={this.state.result_status8}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section9_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section9' in this.state && this.state.section9 ? (this.state.section9 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status9: event.target.value }) }} value={this.state.result_status9}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                            {this.state.section10_enable === '1' &&
                                                <>
                                                    <Grid item xs={4}>{'section10' in this.state && this.state.section10 ? (this.state.section10 + '：') : ''}</Grid>
                                                    <Grid item xs={8}>
                                                        <Radio.Group onChange={(event) => { this.setState({ result_status10: event.target.value }) }} value={this.state.result_status10}>
                                                            <Radio value={"1"} style={{fontSize: '17.5px'}}>出席</Radio>
                                                            <Radio value={"3"} style={{fontSize: '17.5px'}}>Web</Radio>
                                                            <Radio value={"2"} style={{fontSize: '17.5px'}}>欠席</Radio>
                                                        </Radio.Group>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </>
                                    : <>出席者情報が見つかりませんでした。</>
                                }
                            </>
                            : <>会議情報が見つかりませんでした。</>}
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        {this.state.username && !this.state.loading ?
                            <Button color="primary" style={{ outline: "none" }} size="small" onClick={() => { this.updatePresenceResultStatus() }}>確定</Button>
                            : <Button color="primary" style={{ outline: "none" }} size="small" disabled>確定</Button>}
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={() => { this.setState({ conferenceInfoDialogOpen: false, ...clearConferenceInfo }) }}>戻る</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.messageDialogOpen} onClose={() => { this.setState({ conferenceInfoDialogOpen: false, messageDialogOpen: false, ...clearConferenceInfo }) }}
                    aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className="dialog-title-style"></DialogTitle>
                    <DialogContent>
                        <DialogContentText>出欠実績が記入されました。</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#F3F9FD' }}>
                        <Button color="primary" style={{ outline: "none" }} size="small" onClick={() => { this.setState({ conferenceInfoDialogOpen: false, messageDialogOpen: false, ...clearConferenceInfo }) }}>確認</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.userData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (authData, userData) => dispatch(setAuth(authData, userData)),
        setLoading: (loading) => dispatch(setLoading(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceAttendeeMaintenance)